/**
 * 标签页
 */
import { state } from './state'
import { mutations } from './mutations'

export default {
  namespaced: true,
  state,
  mutations,
  getters: {
    tabsList: (state) => state.tabsList,
  },
}
