import ajax from '@/utils/ajax.js'

/**
 * 获取财务账户列表
 * @param {*} params 
 * @returns 
 */
export function getList(params) {
  return ajax.post('/FinancialAccount/getList',params)
}

/**
 * 获取财务账户详情
 * @param {*} params 
 * @returns 
 */
 export function getDetail(params) {
  return ajax.post('/FinancialAccount/detail',params)
}


/**
 * 添加财务账户
 * @param {*} params 
 * @returns 
 */
 export function add(params) {
  return ajax.post('/FinancialAccount/add',params)
}

/**
 * 修改财务账户
 * @param {*} params 
 * @returns 
 */
 export function update(params) {
  return ajax.post('/FinancialAccount/update',params)
}