
import { config } from './config_online.js'
// import { config } from './config_test.js'


// 后台类型：管理后台、工厂对账后台
if(document.location.hostname.indexOf("expbill") >= 0){
	config.TYPE = "expbill"
}else{
	config.TYPE = "admin"
}

// debug
// config.TYPE = "admin"

export default config