
import store from '@/store/index'

import { getFinanceCityList } from '@/api/outlets.js'
import { getList } from '@/api/system/account.js'


// 财务账户
export async function getFinanceList(onlyManage) {
	const list = store.getters['authAccountList']
	if(list && list.length) {
		return list.map(el=>{
			el.id = el.financial_account_id
			el.name = el.financial_account_name
			return el
		})
	}else{
		if(!onlyManage){
			const { code, data } = await getList()
			return code == 0 ? data.list : []
		}
	}
	return []
}
export function isFinanceManage() {
	const list = store.getters['authAccountList']
	return !!(list && list.length)
}

// 城市市场
export async function getMarketCityList(onlyManage) {
	const list = store.getters['authCityList']
	// 如果是城市市场账号
	if(list && list.length) {
		return list
	}else{
		if(!onlyManage){
			const { code, data } = await getFinanceCityList()
			return code == 0 ? data.list : []
		}
	}
	return []
}
export function isCityManage() {
	const list = store.getters['authCityList']
	return !!(list && list.length)
}

// 网点
// export function getOutlets() {
	
// }

export function checkAuth(command){
	const authData = store.getters['authData']
    return !!(authData && authData[command])
}
export function checkPage(path){
	const menuList = store.getters['menuList']
	if(!menuList || !menuList.length) return false
	return menuList.some(menu=>{
		if(menu.path == path) return true
		if(menu.submenu_list && menu.submenu_list.length){
			return menu.submenu_list.some(sub=>{
				return sub.path == path
			})
		}
		return false
	})
}