<template>
  <div class="logo">
    <!-- <img src="~@/assets/logo.svg" alt="" /> -->
    <h2 class="title">{{isTest ? '【测试环境】':''}}好利来</h2>
  </div>
</template>

<script>

import config from '@/utils/config.js'

export default {
  name: "Index",
  props: {
    collapsed: {
      type: Boolean
    }
  },
  data () {
    return {
      isTest: config.ENV_TEST
    }
  },
}
</script>

<style lang="less" scoped>
.logo {
  display: flex;
  align-items: center;
  padding-left: 24px;
  height: 64px;
  line-height: 64px;
  overflow: hidden;
  white-space: nowrap;

  img {
    height: 32px;
    margin-right: 8px;
  }

  .title {
    margin-bottom: 0;
    color: #FFFFFF;
  }
}
</style>
