<template>
  <div class="tabs-view" :style="{width: width }">
    <a-tabs :activeKey="activeKey"
            hide-add
            type="editable-card"
            class="tabs"
            @change="changePage"
            :style="{ marginBottom: '0' }"
            @edit="editTabItem">
      <template v-for="(pageItem) in tabsList">
        <a-tab-pane :key="pageItem.fullPath"
                    :tab="pageItem.meta.title">
        </a-tab-pane>
      </template>

      <template #tabBarExtraContent>
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link"
             @click.prevent>
            <a-icon :style="{ fontSize: '20px' }"
                    type="down" />
          </a>
          <template #overlay>
            <a-menu style="user-select: none">
              <a-menu-item key="1"
                           :disabled="activeKey !== $route.fullPath"
                           @click="reloadPage">
                <a-icon type="reload" />
                刷新
              </a-menu-item>
              <a-menu-item key="2"
                           @click="removeTab($route)">
                <a-icon type="close" />
                关闭
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="5"
                           @click="closeOther($route)">
                <a-icon type="column-width" />
                关闭其他
              </a-menu-item>
              <a-menu-item key="6"
                           @click="closeAll">
                <a-icon type="minus" />
                关闭全部
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'TabsView',
  props: {
    asiderWidth:{
      type: String,
      default: '258px'
    }
  },
  computed: {
    ...mapGetters('tabsView', ["tabsList"]),
    width(){
      return `calc(100vw - ${this.asiderWidth})`
    }
  },
  data () {
    return {
      activeKey: '',
      whiteList: ['Redirect', 'login']
    }
  },
  mounted () {
    if (this.whiteList.includes(this.$route.name)) return
    this.activeKey = this.$route.fullPath
    this.$store.commit('tabsView/addTabs', this.getSimpleRoute(this.$route))
  },
  methods: {
    // 获取简易的路由对象
    getSimpleRoute (route) {
      const { fullPath, hash, meta, name, params, path, query } = route
      return { fullPath, hash, meta, name, params, path, query }
    },
    changePage (key) {
      this.activeKey = key
      this.$router.push(key)
    },
    editTabItem (targetKey, action) {
      if (action == 'remove') {
        this.removeTab(this.tabsList.find((item) => item.fullPath == targetKey))
      }
    },
    removeTab (route) {
      if (this.tabsList.length === 1) {
        return this.$message.warning('这已经是最后一页，不能再关闭了！')
      }
      this.delKeepAliveCompName()
      // tabsViewMutations.closeCurrentTabs(route)
      this.$store.commit('tabsView/closeCurrentTab', route)
      // 如果关闭的是当前页
      if (this.activeKey === route.fullPath) {
        const currentRoute = this.tabsList[Math.max(0, this.tabsList.length - 1)]
        this.activeKey = currentRoute.fullPath
        this.$router.push(currentRoute)
      }
    },
    delKeepAliveCompName () {
      if (this.$route.meta.keepAlive) {
        const name = this.$router.currentRoute.matched.find((item) => item.name == this.$route.name)
          .components.default.name
        if (name) {
          this.$store.state.asyncRoute.keepAliveComponents =
            this.$store.state.asyncRoute.keepAliveComponents.filter((item) => item != name)
        }
      }
    },
    // 刷新页面
    reloadPage () {
      this.delKeepAliveCompName()
      // this.$router.push({
      //   path: '/redirect' + this.$route.fullPath
      // })
      this.$router.go(0);
    },

    // 关闭左侧
    closeLeft (route) {
      this.$store.commit('tabsView/closeLeftTabs', route)
      this.$state.activeKey = route.fullPath
      this.$router.replace(route.fullPath)
    },

    // 关闭右侧
    closeRight (route) {
      this.$store.commit('tabsView/closeRightTabs', route)
      this.$state.activeKey = route.fullPath
      this.$router.replace(route.fullPath)
    },

    // 关闭其他
    closeOther (route) {
      this.$store.commit('tabsView/closeOtherTabs', route)
      this.activeKey = route.fullPath
      this.$router.replace(route.fullPath)
    },

    // 关闭全部
    closeAll () {
      localStorage.removeItem('routes')
      this.$store.commit('tabsView/closeAllTabs')
      this.$router.replace('/')
    }
  },
  watch: {
    '$route' (to) {
      if (this.whiteList.includes(this.$route.name)) return
      this.activeKey = to.fullPath
      this.$store.commit('tabsView/addTabs', this.getSimpleRoute(this.$route))
    }
  }
}
</script>


<style lang="less">
.tabs-view {
  border-top: 1px solid #eee;
  // width: calc(100vw - 258px);
  .tabs {
    .ant-tabs-bar {
      padding: 0px 20px 0 10px;
      margin: 0;
      background-color: white;
      user-select: none;
    }

    .ant-tabs-tabpane {
      display: none;
    }

    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      .anticon-close {
        width: 0;
        transition: width 0.3s;
      }

      &:hover .anticon-close {
        width: 16px;
      }
    }
  }

  .tabs-view-content {
    /* height: calc(100vh - #{$header-height}); */
    height: calc(100vh - 110px);
    padding: 20px 14px 0;
    overflow: auto;
  }
}
</style>
