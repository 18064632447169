import Cookies from 'js-cookie'
import CONFIG from '@/utils/config.js'
import { 
	COOKIE_ACCESS_TOKEN, 
	// ACCESS_TOKEN
} from '@/store/mutation-types'

// 30天过期
const defaultExpires = new Date(new Date().getTime() + 30 * 24 * 3600 * 1000)


function isSubdomain(subdomain, domain) {
  const regex = new RegExp(`^(?:[^.]+\\.)*?(${domain})$`)
  return regex.test(subdomain)
}
let domain = document.location.hostname
// 如果页面域名是COOKIE_DOMAIN的子域名，就使用COOKIE_DOMAIN（admin和expbill共享cookie）
if(isSubdomain(document.location.hostname, CONFIG.COOKIE_DOMAIN)){
	domain = CONFIG.COOKIE_DOMAIN
}

export function getToken() {
	// const tk_cookie = Cookies.get(COOKIE_ACCESS_TOKEN)
	// const tk_storage = localStorage.getItem(ACCESS_TOKEN)
	return Cookies.get(COOKIE_ACCESS_TOKEN)
	// return Cookies.get(COOKIE_ACCESS_TOKEN) || localStorage.getItem(ACCESS_TOKEN) || ''
}

export function setToken(token) {
	// localStorage.setItem(ACCESS_TOKEN, token)
	Cookies.set(COOKIE_ACCESS_TOKEN, token, { domain: domain, expires: defaultExpires})
}

export function removeToken() {
	// localStorage.removeItem(ACCESS_TOKEN)
	Cookies.remove(COOKIE_ACCESS_TOKEN, { domain: domain })
}
