import ajax from '@/utils/ajax.js'

// 登录
export function login(params) {
  return ajax.post('/User/login',params)
}
// 检查登录态
export function checkLogin(params) {
  return ajax.post('/User/checkLogin',params)
}


// 获取登录人个人信息
export function getUserInfo(params) {
  return ajax.post('/UserCenter/getPersonalInfo',params)
}

// 修改登录人个人信息
export function updateUserPassword(params) {
  return ajax.post('/UserCenter/updatePersonalInfo',params)
}

// 修改登录人个人信息
export function updateUserName(params) {
  return ajax.post('/UserCenter/updatePersonalInfo',params)
}