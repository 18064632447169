
export const config = {

	ENV_TEST: false,

	VERSION: "1.0.12",

	HOST_IMG_URL: 'https://p1-static-holiland.1900m.com/',
	HOST_IMG_P2: 'https://p2-static-holiland.1900m.com/',
	
	BASE_URL: 'https://api-admin-holiland.1900m.com',

	MAP_API_DOMAIN: 'https://apis.map.qq.com',

	// HLL_WXACT_DOMAIN: 'https://wx-holiland.1900m.com',
	HLL_WXACT_DOMAIN: 'https://wx.holiland.com',
	
	// MAP_KEY: 'W3NBZ-SRBWU-VN4V2-B7O3L-B6ETT-O6FMG',
	MAP_KEY: 'CDRBZ-ZRW3Q-KK65D-GWOXN-GDRJH-ABBWI',
	
	COOKIE_DOMAIN: 'holiland.1900m.com',
}

