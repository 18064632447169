import {IS_LOCKSCREEN} from "@/store/mutation-types";

// 长时间不操作默认锁屏时间
const initTime = 60 * 60

export const mutations = {
    setLock: (state, payload) => {
        state.isLock = payload
        localStorage.setItem(IS_LOCKSCREEN, state.isLock)
    },
    setLockTime: (state, payload = initTime) => {
        state.lockTime = payload
    },
}
