import Vue from "vue";
import Router from "vue-router";
import { getToken } from '@/utils/auth'

Vue.use(Router);

//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const loginRoutePath = "/login";
const defaultRoutePath = "/dashboard";
const whiteList = ["/login"]; // 不重定向白名单

/* Layout */
import Layout from "@/layout/index";
export const routes = [
  {
    path: "/",
    name: "Layout",
    adm: "admin,expbill",
    component: Layout,
    redirect: "/dashboard",
    meta: {
      icon: "",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "主页",
          icon: "icon-zhuomian",
        },
        component: () => import("@/pages/dashboard/index"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    adm: "admin,expbill",
    hidden: true,
    component: () => import("@/pages/login/index"),
  },
  {
    name: "goods-store",
    path: "/goods-store",
    meta: {
      hidden: false,
      title: "商品管理",
      icon: "icon-shangpinguanli1",
    },
    component: Layout,
    children: [
      {
        path: "/goods",
        name: "goods",
        meta: {
          hidden: false,
          title: "商品库",
          icon: "icon-shangpin",
        },
        component: () => import("@/pages/goods/index"),
      },
      {
        path: "/goods-edit",
        name: "goods-edit",
        meta: {
          hidden: true,
          title: "商品详情",
          icon: "icon-zhuomian",
        },
        component: () => import("@/pages/goods/edit"),
      },
      {
        path: "/invoice_goods_type",
        name: "invoiceCategory",
        meta: {
          hidden: false,
          title: "发票类目",
          icon: "icon-shangpin",
        },
        component: () => import("@/pages/invoice-category/index"),
      },
    ],
  },
  {
    path: "/outlets-manage",
    name: "outlets-manage",
    meta: {
      hidden: false,
      title: "门店管理",
      icon: "icon-mendian",
    },
    component: Layout,
    children: [
      {
        path: "/outlets",
        name: "outlets",
        meta: {
          hidden: false,
          title: "门店/配送站",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/outlets/index"),
      },
      {
        path: "/outlets-edit",
        name: "outlets-edit",
        meta: {
          hidden: true,
          title: "网点编辑",
          icon: "icon-peisong",
        },
        component: () => import("@/pages/outlets/edit.vue"),
      },
      {
        path: "/outlets-delivery-area",
        name: "outlets-delivery-area",
        meta: {
          hidden: false,
          title: "配送范围",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/outlets/delivery-area"),
      },
      {
        path: "/outlets-delivery-map",
        name: "outlets-delivery-map",
        meta: {
          hidden: false,
          title: "配送地图",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/outlets/delivery-map"),
      },
      {
        path: "/outlets-area-config",
        name: "outlets-area-config",
        meta: {
          hidden: false,
          title: "售价区域模板",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/outlets/area-config"),
      },
    ],
  },
  {
    path: "/shop",
    name: "shop",
    meta: {
      hidden: false,
      title: "门店经营系统",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/shop-config",
        name: "shop-config",
        meta: {
          hidden: false,
          title: "门店配置",
          icon: "icon-cangku",
        },
        component: () => import("@/pages/shop/config/index"),
      },
      {
        path: "/current-stock",
        name: "current-stock",
        meta: {
          hidden: false,
          title: "当前库存",
          icon: "icon-cangku",
        },
        component: () => import("@/pages/shop/current-stock/index"),
      },
      {
        path: "/shop-sell-goods",
        name: "shop-sell-goods",
        meta: {
          hidden: false,
          title: "售卖商品",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/goods/index"),
      },
      {
        path: "/shop-advert",
        name: "shop-advert",
        meta: {
          hidden: false,
          title: "门店广告位",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/advert/index"),
      },
      {
        path: "/shop-online-order",
        name: "shop-online-order",
        meta: {
          hidden: false,
          title: "线上订单",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/order/index"),
      },
      {
        path: "/shop-order-detail",
        name: "shop-order-detail",
        meta: {
          hidden: false,
          title: "订单详情",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/order/order-detail"),
      },
      {
        path: "/shop-place-order",
        name: "shop-place-order",
        meta: {
          hidden: false,
          title: "本店下单",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/place-order"),
      },
      {
        path: "/ocro-verification-record",
        name: "shop-offline-flow",
        meta: {
          hidden: false,
          title: "线下核销记录",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/offline-flow"),
      },
      {
        path: "/shop-customer-refund",
        name: "shopRefund",
        meta: {
          hidden: false,
          title: "售后退款",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/refund"),
      },
      {
        path: "/shop-courier",
        name: "shop-courier",
        meta: {
          hidden: false,
          title: "配送员",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/courier"),
      },
      {
        path: "/shop-order-add",
        name: "shop-order-add",
        meta: {
          hidden: false,
          title: "新增订单",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/shop/order/order-add"),
      },
    ],
  },
  {
    path: "/goods-operation",
    name: "goods-operation",
    meta: {
      hidden: false,
      title: "商品运营",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/shop-sell",
        name: "shop-sell",
        meta: {
          hidden: false,
          title: "小程序门店在售",
          icon: "icon-quyu",
        },
        component: () => import("@/pages/shop/sell/index"),
      },
      {
        path: "/mall-sell",
        name: "mall-sell",
        meta: {
          hidden: false,
          title: "小程序商城在售",
          icon: "icon-shangpin",
        },
        component: () => import("@/pages/mall/index"),
      },
      {
        path: "/pc-mall-sell",
        name: "pc-mall-sell",
        meta: {
          hidden: false,
          title: "网站在售",
          icon: "icon-shangpin",
        },
        component: () => import("@/pages/pc/pc-mall-sell/index"),
      },
      {
        path: "/goods-sale-model",
        name: "goods-sale-model",
        meta: {
          hidden: false,
          title: "预约/预售管理",
          icon: "icon-cangku",
        },
        component: () => import("@/pages/goods/sale-model"),
      },
      {
        path: "/mall-online-store",
        name: "mall-online-store",
        meta: {
          hidden: false,
          title: "虚拟仓库",
          icon: "icon-cangku",
        },
        component: () => import("@/pages/online-warehouse/index"),
      },
      {
        path: "/storehouse",
        name: "storehouse",
        meta: {
          hidden: true,
          title: "虚拟仓库库存",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/online-warehouse/storehouse/index"),
      },
      {
        path: "/stock",
        name: "stock",
        meta: {
          hidden: false,
          title: "虚拟仓库商品库存",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/stock/index"),
      },
      {
        path: "/mall-wdt-store",
        name: "mallWdtStore",
        meta: {
          hidden: false,
          title: "发货工厂配置",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/online-warehouse/deliver-config"),
      },
      {
        path: "/express-sku",
        name: "express-sku",
        meta: {
          hidden: false,
          title: "快递商品SKU码",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/sku/sku-list"),
      },
      {
        path: "/express-freight",
        name: "express-freight",
        meta: {
          hidden: false,
          title: "快递运费",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/online-warehouse/freight"),
      },
    ],
  },
  {
    path: "/activity-operation",
    name: "activity-operation",
    meta: {
      hidden: false,
      title: "活动运营",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/full-reduce",
        name: "full-reduce",
        meta: {
          hidden: false,
          title: "商城满额活动",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/full-reduce/index"),
      },
      {
        path: "/full-reduce-edit",
        name: "full-reduce-edit",
        meta: {
          hidden: false,
          title: "编辑满额活动",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/full-reduce/edit"),
      },
      {
        path: "/coupon",
        name: "coupon",
        meta: {
          hidden: false,
          title: "优惠券",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/coupon/index"),
      },
      {
        path: "/coupon-record",
        name: "coupon-record",
        meta: {
          hidden: false,
          title: "优惠券领取记录",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/coupon/record"),
      },
      {
        path: "/coupon-add",
        name: "coupon-add",
        meta: {
          hidden: false,
          title: "新建优惠券",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/coupon/add"),
      },
      {
        path: "/coupon-detail",
        name: "coupon-detail",
        meta: {
          hidden: false,
          title: "优惠券详情",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/coupon/add"),
      },
      {
        path: "/online-coupon-event",
        name: "online-coupon-event",
        meta: {
          hidden: false,
          title: "优惠券活动-线上",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/coupon-event/online"),
      },
      {
        path: "/shop-coupon-event",
        name: "shop-coupon-event",
        meta: {
          hidden: false,
          title: "优惠券活动-线下",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/coupon-event/city"),
      },
      {
        path: "/freight",
        name: "freight",
        meta: {
          hidden: false,
          title: "运费模板",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/freight"),
      },
      {
        path: "/freight-temp",
        name: "freight-temp",
        meta: {
          hidden: false,
          title: "临时运费策略",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/freight/freight-temp"),
      },
      {
        path: "/stored-card",
        name: "vipcard-index",
        meta: {
          hidden: false,
          title: "钱包充值套餐",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/vip-card"),
      },
      {
        path: "/recharge-card",
        name: "vipcard-gift",
        meta: {
          hidden: false,
          title: "钱包礼品卡",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/vip-card/gift"),
      },
      {
        path: "/area-manager",
        name: "area-manager",
        meta: {
          hidden: false,
          title: "区域套餐",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/vip-card/area-manager"),
      },
      {
        path: "/limit-discounts",
        name: "limit-discounts",
        meta: {
          hidden: false,
          title: "商品限时优惠",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/limit-discounts"),
      },
      {
        path: "/limit-discounts-edit",
        name: "limit-discounts-edit",
        meta: {
          hidden: true,
          title: "新建商品限时优惠",
          icon: "icon-peisong",
        },
        component: () => import("@/pages/activity/limit-discounts/edit.vue"),
      },
      {
        path: "/integral",
        name: "integral",
        meta: {
          hidden: false,
          title: "积分兑换",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/integral"),
      },
      {
        path: "/wx-subscription-event",
        name: "wx-subscription-event",
        meta: {
          hidden: false,
          title: "微信订阅消息",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/activity/wechat-activity-subscription/index"),
      },
      {
        path: "/advert-position",
        name: "advert-position",
        meta: {
          hidden: false,
          title: "小程序广告位",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/advert-position/index"),
      },
      {
        path: "/pc-advert-position",
        name: "pc-advert-position",
        meta: {
          hidden: false,
          title: "PC广告位",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/pc/pc-advert-position/index"),
      },
      {
        path: "/wx-event-ad",
        name: "web-ad",
        meta: {
          hidden: false,
          title: "活动广告",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/pc/web-ad-page/index"),
      },
      {
        path: "/web-ad-edit",
        name: "web-ad-edit",
        meta: {
          hidden: false,
          title: "活动广告详情",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/pc/web-ad-page/edit"),
      },
    ],
  },
  {
    path: "/finance",
    name: "finance-manage",
    meta: {
      hidden: false,
      title: "财务管理",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/shop-financial-bill",
        name: "shop-financial-bill",
        meta: {
          hidden: false,
          title: "门店财务",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/shop"),
      },
      {
        path: "/financial-account-bill",
        name: "financial-account-bill",
        meta: {
          hidden: false,
          title: "账户总览",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/account"),
      },
      {
        path: "/vip-card-corp",
        name: "vipcardCorp",
        meta: {
          hidden: false,
          title: "钱包备案",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/wallet-corp"),
      },
      {
        path: "/financial-coupon",
        name: "financial-coupon",
        meta: {
          hidden: false,
          title: "优惠券",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/coupon/index"),
      },
      {
        path: "/yd-order",
        name: "financial-coupon-yidong",
        meta: {
          hidden: false,
          title: "移动积分换券",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/coupon/yidong"),
      },
      {
        path: "/financial-bill-export",
        name: "financial-bill-export",
        meta: {
          hidden: false,
          title: "账户明细导出",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/accountexport"),
      },
      {
        path: "/export_data-report-financial",
        name: "export_data-report-financial",
        meta: {
          hidden: false,
          title: "财务收款订单",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/analysis/finance-order-export"),
      },
      {
        path: "/export_data-report-deliver",
        name: "export_data-report-deliver",
        meta: {
          hidden: false,
          title: "账户交付数据",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/analysis/sub-finance-order-export"),
      },
      {
        path: "/regional-financial-bill",
        name: "/regional-financial-bill",
        meta: {
          hidden: false,
          title: "城市订单",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/area"),
      },
      {
        path: "/hq-financial-bill",
        name: "/hq-financial-bill",
        meta: {
          hidden: false,
          title: "总部财务",
          icon: "icon-kucun",
        },
        component: () => import("@/pages/finance/group"),
      },
    ],
  },
  {
    path: "/trading-manage",
    name: "trading-manage",
    meta: {
      hidden: false,
      title: "交易管理",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/city-invoice-goods-type",
        name: "cityInvoiceType",
        meta: {
          title: "城市发票类目",
        },
        component: () => import("@/pages/voluntarily-invoice/index"),
      },
      {
        path: "/refund",
        name: "refund",
        meta: {
          title: "售后退款",
        },
        component: () => import("@/pages/trading-manage/refund/index"),
      },{
        path: "/vip-card-refund",
        name: "wallet-refund",
        meta: {
          title: "钱包余额退款",
        },
        component: () => import("@/pages/trading-manage/wallet-refund/index"),
      },
      {
        path: "/order-invoice",
        name: "order-invoice",
        meta: {
          title: "订单发票",
        },
        component: () => import("@/pages/invoice/order-invoice/index"),
      },
      {
        path: "/topup-invoice",
        name: "topup-invoice",
        meta: {
          title: "钱包充值发票",
        },
        component: () => import("@/pages/invoice/topup-invoice/index"),
      },
      {
        path: "/recharge-card-invoice",
        name: "gift-invoice",
        meta: {
          title: "礼品卡发票",
        },
        component: () => import("@/pages/invoice/gift-invoice/index"),
      },
      // {
      //   path: "/total-sell",
      //   name: "total-sell",
      //   meta: {
      //     title: "总交易流水",
      //   },
      //   component: () => import("@/pages/trading-manage/total-sell/index"),
      // },
      {
        path: "/account",
        name: "account",
        meta: {
          title: "账户入账",
        },
        component: () => import("@/pages/trading-manage/account/index"),
      },
      {
        path: "/account-settle",
        name: "account-settle",
        meta: {
          title: "账户结算",
        },
        component: () => import("@/pages/trading-manage/account-settle/index"),
      },
      {
        path: "/member",
        name: "member",
        meta: {
          title: "会员管理",
        },
        component: () => import("@/pages/member/index"),
      },
      {
        path: "/member-detail",
        name: "memberDetail",
        hidden: true,
        meta: {
          title: "会员详情",
        },
        component: () => import("@/pages/member/detail"),
      },
    ],
  },
  {
    path: "/customer-service",
    name: "customer-service",
    meta: {
      hidden: false,
      title: "客户服务中心",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      // {
      //   path: "/order",
      //   name: "order",
      //   meta: {
      //     title: "全部订单",
      //   },
      //   component: () => import("@/pages/customer-service/order/index"),
      // },
      {
        path: "/order",
        name: "order",
        meta: {
          title: "订单管理",
        },
        component: () => import("@/pages/customer-service/order/list"),
      },
      {
        path: "/not-finish-express-other",
        name: "pending-order",
        meta: {
          title: "未完成快递单",
        },
        component: () => import("@/pages/customer-service/order/express-pending-list"),
      },
      {
        path: "/order-detail",
        name: "order-detail",
        hidden: true,
        meta: {
          title: "订单详情",
        },
        component: () => import("@/pages/customer-service/order/detail"),
      },
      {
        path: "/order-add",
        name: "order-add",
        meta: {
          title: "新增订单",
          keepAlive: true,
        },
        component: () => import("@/pages/customer-service/order/add"),
      },
      // {
      //   path: "/order-cake",
      //   name: "order-cake",
      //   meta: {
      //     title: "蛋糕订单",
      //   },
      //   component: () => import("@/pages/customer-service/order-cake/index"),
      // },
      {
        path: "/other-order",
        name: "order-thirdparty",
        meta: {
          title: "第三方订单",
        },
        component: () => import("@/pages/customer-service/order-thirdparty/index"),
      },
      {
        path: "/order-thirdparty-detail",
        name: "otherPlatOrderDetail",
        hidden: true,
        meta: {
          title: "第三方订单详情",
        },
        component: () => import("@/pages/customer-service/order-thirdparty/detail"),
      },
      {
        path: "/order-thirdparty-auto",
        name: "otherPlatOrderAuto",
        hidden: true,
        meta: {
          title: "第三方订单-自动建单",
        },
        component: () => import("@/pages/customer-service/order-thirdparty/auto-detail"),
      },
      // {
      //   path: "/orderAudit",
      //   name: "orderAudit",
      //   hidden: true,
      //   meta: {
      //     title: "订单审核",
      //   },
      //   component: () => import("@/pages/customer-service/order-cake/orderAudit"),
      // },
      {
        path: "/customer-refund",
        name: "customer-refund",
        meta: {
          title: "售后退款",
        },
        component: () => import("@/pages/customer-service/refund/index"),
      },{
        path: "/order-refund-flow",
        name: "order-refund-flow",
        meta: {
          title: "订单退款记录",
        },
        component: () => import("@/pages/customer-service/refund/flow"),
      },
      {
        path: "/gift-order",
        name: "gift-order",
        meta: {
          title: "礼品卡订单",
        },
        component: () => import("@/pages/customer-service/gift-order/index"),
      },
      {
        path: "/kf-vip-card-refund",
        name: "customer-wallet-refund",
        meta: {
          title: "钱包余额退款",
        },
        component: () => import("@/pages/customer-service/wallet-refund/index"),
      },
    ],
  },
  {
    path: '',
    meta: {
      title: "提货卡",
    },
    component: Layout,
    children:[{
      path: "/svcgb-look",
      name: "group-purchase",
      meta: {
        title: "企业团购提货卡",
      },
      component: () => import("@/pages/storevalue-card/group-purchase"),
    },{
      path: "/svcgb-import",
      name: "group-purchase-import",
      meta: {
        title: "企业团购导入",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/storevalue-card/import"),
    }],
  },
  {
    path: "",
    name: "账号管理",
    component: Layout,
    children: [
      {
        path: "/role",
        name: "role",
        meta: {
          title: "角色管理",
        },
        component: () => import("@/pages/system/role"),
      },{
        path: "/staff",
        name: "staff",
        meta: {
          title: "账号管理",
        },
        component: () => import("@/pages/system/staff"),
      },{
        path: "/regional_staff",
        name: "regional_staff",
        meta: {
          title: "城市账号",
        },
        component: () => import("@/pages/system/staff/regional-staff"),
      },{
        path: "/courier",
        name: "courier",
        meta: {
          title: "配送员账号",
        },
        component: () => import("@/pages/system/staff/courier"),
      },
    ],
  },
  {
    path: '',
    meta: {
      title: "系统管理",
    },
    component: Layout,
    children:[{
      path: "/personal-center",
      name: "personal-center",
      meta: {
        title: "个人中心",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/system/personal"),
    },{
      path: "/financial-account",
      name: "financial-account",
      meta: {
        title: "财务账户",
      },
      component: () => import("@/pages/system/finance-account"),
    },{
      path: "/pay-config",
      name: "pay-config",
      meta: {
        title: "支付配置",
      },
      component: () => import("@/pages/system/finance-payment"),
    },{
      path: "/offline-cash-register",
      name: "offline-cash-register",
      meta: {
        title: "收银软件",
      },
      component: () => import("@/pages/system/cashier"),
    },{
      path: "/offline-cash-register-push",
      name: "offline-cash-register-push",
      meta: {
        title: "收银软件推送",
      },
      component: () => import("@/pages/system/cashier/push"),
    }]
  },
  {
    path: "/msg",
    name: "msg",
    meta: {
      hidden: false,
      title: "留言板",
      icon: "icon-jifenshangcheng",
    },
    component: Layout,
    children: [
      {
        path: "/message-board",
        name: "msgBoard",
        meta: {
          title: "留言板列表",
        },
        component: () => import("@/pages/system/msgboard/index"),
      },
    ],
  },
  {
    path: 'regional',
    meta: {
      title: "区域管理",
    },
    component: Layout,
    children:[{
      path: "/regional-shop-advert",
      name: "regional-shop-advert",
      meta: {
        title: "广告位管理",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/regional/advert"),
    },{
      path: "/city-config",
      name: "regional-config",
      meta: {
        title: "城市配置",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/regional/config"),
    },{
      path: "/city-rationed-stock",
      name: "pre-sale",
      meta: {
        title: "限量预售管理",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/regional/sell/pre-sale"),
    },{
      path: "/city-day-limitation",
      name: "act-cake-daily",
      meta: {
        title: "活动蛋糕日交付限制",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/regional/sell/act-cake-daily"),
    },{
      path: "/regional-shop-sell",
      name: "regional-shop-sell",
      meta: {
        title: "网点在售",
        icon: "icon-kucun",
      },
      component: () => import("@/pages/regional/sell"),
    },{
      path: "/regional-shop-sell-detail",
      name: "shopSellDetail",
      meta: {
        title: "网点在售明细",
        icon: "icon-kucun",
        hidden: true,
      },
      component: () => import("@/pages/regional/sell/detail"),
    },{
      path: "/regional-to-be-continued-goods",
      name: "order-goods",
      meta: {
        title: "待交付产品",
      },
      component: () => import("@/pages/regional/analysis/order-goods"),
    },{
      path: "/regional-out-stock-remind",
      name: "regional-out-stock-remind",
      meta: {
        title: "补货提醒",
      },
      component: () => import("@/pages/regional/sell/remind"),
    },{
      path: "/regional-order-goods-overview",
      name: "product-sell-overview",
      meta: {
        title: "产品销售统计",
      },
      component: () => import("@/pages/regional/analysis/product-sell"),
    },{
      path: "/regional-courier",
      name: "regional-courier",
      meta: {
        title: "城市网点配送员",
      },
      component: () => import("@/pages/system/staff/courier"),
    }],
  },
  {
    path: "/express-bill",
    name: "express-bill",
    adm: "expbill",
    meta: {
      hidden: false,
      title: "工厂对账",
      icon: "icon-mendian",
    },
    component: Layout,
    children: [
      {
        path: "/other-platform-bill",
        name: "expressOverview",
        meta: {
          hidden: false,
          title: "月度总览",
        },
        component: () => import("@/pages/express/overview"),
      },
      {
        path: "/express-order",
        name: "express-order",
        meta: {
          hidden: false,
          title: "第三方订单",
        },
        component: () => import("@/pages/express/order.vue"),
      },
      {
        path: "/express-waybill",
        name: "express-waybill",
        meta: {
          hidden: false,
          title: "订单发货",
        },
        component: () => import("@/pages/express/waybill.vue"),
      },
      {
        path: "/express-refund",
        name: "express-refund",
        meta: {
          hidden: false,
          title: "退款列表",
        },
        component: () => import("@/pages/express/refund.vue"),
      },
    ],
  },
  {
    path: "/shop-order-print",
    name: "shop-order-print",
    hidden: true,
    component: () => import("@/pages/shop/order/order-print"),
  },
];

const router = new Router({
  // mode: "history", //后端支持可开
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const token = getToken()
  if (token) {
    if (to.name === "login") {
      next({ path: defaultRoutePath });
    } else {
      // 在免登录名单，直接进入
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({
        path: loginRoutePath,
        query: { redirect: to.fullPath },
        replace: true,
      });
    }
  }
});
export default router;
