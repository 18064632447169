import Vue from 'vue'
import App from './App.vue'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './styles/index.css'
import './styles/common.css'


import router from './router'
import store from './store'
import {VueJsonp} from 'vue-jsonp'

import BaseTable from '@/components/base-table'


Vue.component('BaseTable', BaseTable)

Vue.use(Antd)

Vue.use(VueJsonp)

Vue.prototype.$store = store
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
