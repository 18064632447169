/**
 * 锁屏
 */

import {state} from './state';
import {mutations} from './mutations';

export default {
    namespaced: true,
    state,
    mutations,
};

