import {IS_LOCKSCREEN} from '@/store/mutation-types'

// 长时间不操作默认锁屏时间
const initTime = 60 * 60

const isLock = localStorage.getItem(IS_LOCKSCREEN, false)


export const state = {
    isLock: isLock === true, // 是否锁屏
    lockTime: isLock == 'true' ? initTime : 0
};
